import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UniHttp } from '../../../framework/core/http/http';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { ReportDefinition } from '../../unientities';
import { Observable } from 'rxjs';
import { FeaturePermissionService } from '@app/featurePermissionService';

export enum ReportNames {
    PAYCHECK_FROM_TO = 'Lønnslipp',
    PAYCHECK_EMP_FILTER = 'Lønnslipp_emp_filter',
    ANNUAL_STATEMENT = 'Årsoppgave',
    BARNEPASS = 'Barnepass',
}

@Injectable({ providedIn: 'root' })
export class ReportDefinitionService extends BizHttp<ReportDefinition> {
    constructor(
        uniHttp: UniHttp,
        private permissionService: FeaturePermissionService,
    ) {
        super(uniHttp);
        this.relativeURL = ReportDefinition.RelativeUrl;
        this.entityType = ReportDefinition.EntityType;
        this.DefaultOrderBy = 'Category';
    }

    GetAll(query?: string, expand?: string[]) {
        return super.GetAll(query, expand).pipe(
            map((reports: ReportDefinition[]) =>
                (reports || []).filter((report) => {
                    return this.permissionService.canShowReport(report.UniqueReportID);
                }),
            ),
        );
    }

    getReportByName(name: string): Observable<any> {
        return this.GetAll(`filter=Name eq '${name}'`).pipe(
            map((reports) => {
                return reports[0];
            }),
        );
    }

    getReportByID(id: number): Observable<any> {
        return this.GetAll(`filter=ID eq '${id}'`).pipe(
            map((reports) => {
                return reports[0];
            }),
        );
    }
}
